import { BaseTaskSlideComponent } from "app/shared/slides/base-task-slide.component";
import { getL10NPathForSlideContent } from "app/shared/slides/slide-helper";
import {IMacOSConfig} from "../../../../../shared-interface-mac-os/layout/mac-os-config.interface";
import {
  defaultMacOSDocuments,
  defaultMacOSApplications, defaultMacOSFinderConfig, macOSApplicationNotes
} from "../../../../../shared-interface-mac-os/layout/mac-os-features.shared";
import {IHotspot} from "../../../../../shared/interfaces/hotspot.interface";

export const macOsBaseV1SlidesL10nPath: string = 'macOsBaseV1.slides.';
export const macOsBaseV1CommonL10nPath: string = 'macOsBaseV1.common.';

export function getL10nPathForMacOsBaseV1SlideContent(component: BaseTaskSlideComponent) {
  let path = macOsBaseV1SlidesL10nPath + getL10NPathForSlideContent((<any>component.constructor).nsComponentId);
  return path;
}

export const macOSSlidesConfig: IMacOSConfig = {
  applications: [
    ...defaultMacOSApplications,
    macOSApplicationNotes
  ],
  documents: defaultMacOSDocuments,
  minimizedWindows: [],
  finderConfig: defaultMacOSFinderConfig,
  dockConfig: {}
};

export const safariMinimized: IHotspot = {
  id: 'safari-ns-minimized',
  label: 'sharedInterfaceMacOs.common.homeNorthstarDigitalLiteracyAssessment'
};

export const calendarAppForDock: IHotspot = {
  id: 'mac-os-calendar',
  label: 'sharedInterfaceMacOs.macOsMenuBar.calendar.calendar'
};
