export const grayWindowsDesktopConfig = {
  desktopColor: '#999999'
};

export const taskbarProgramFileExplorer = {
  class: 'file-explorer',
  id: '',
  isFocused: false,
  isOpen: false,
  title: 'sharedInterfaceWin10.common.fileExplorer'
};

export const taskbarProgramGoogleChrome = {
  class: 'chrome',
  id: '',
  isFocused: false,
  isOpen: false,
  title: 'common.googleChrome'
};

export const taskbarProgramFirefox = {
  class: 'firefox',
  id: '',
  isFocused: false,
  isOpen: false,
  title: 'common.mozillaFirefox'
};

export const taskbarProgramMSWord = {
  class: 'word',
  id: '',
  isFocused: false,
  isOpen: false,
  title: 'common.word'
};

export const taskbarProgramMSExcel = {
  class: 'excel',
  id: '',
  isFocused: false,
  isOpen: false,
  title: 'common.excel'
};

export const taskbarProgramEdge = {
  class: 'edge',
  id: '',
  isFocused: false,
  isOpen: false,
  title: 'common.edge'
};
