import {IFileExplorerDocument} from "../../../../../shared-interface-win10/win10-file-explorer.component";
export const grayWindowsDesktopConfig = {
  desktopColor: '#999999'
};

export const taskbarProgramFileExplorer = {
  class: 'file-explorer',
  id: '',
  isFocused: false,
  isOpen: false,
  title: 'sharedInterfaceWin10.common.fileExplorer'
};

export const taskbarProgramMSWord = {
  class: 'word',
  id: '',
  isFocused: false,
  isOpen: false,
  title: 'sharedInterfaceWin10.common.microsoftWord'
};

export const taskbarProgramEdge = {
  class: 'edge',
  id: '',
  isFocused: false,
  isOpen: false,
  title: 'common.edge'
};

export const taskbarProgramExcel = {
  class: 'excel',
  id: '',
  isFocused: false,
  isOpen: false,
  title: 'sharedInterfaceWin10.common.microsoftExcel'
};

export const taskbarSetFileExplorerEdgeWordAndExcel = [
  taskbarProgramFileExplorer,
  taskbarProgramEdge,
  taskbarProgramMSWord,
  taskbarProgramExcel,
];


// DOCUMENTS

export const windowsDocumentClassSchedule: IFileExplorerDocument = {
  icon: 'excel.png',
  id: 'september-class-schedule',
  name: 'msExcelOffice2016Practice.shared.files.classSchedule',
  dateModified: new Date(),
  size: 50,
  type: 'excel',
  selected: false
};

export const windowsDocumentEnglishSyllabus: IFileExplorerDocument = {
  icon: 'pdf.png',
  id: 'intermediate-english-syllabus',
  name: 'msExcelOffice2016Practice.shared.files.intermediateEnglishSyllabus',
  dateModified: new Date(),
  size: 102,
  type: 'pdf',
  selected: false
};

export const windowsDocumentSept5MathHomework: IFileExplorerDocument = {
  icon: 'word.png',
  id: 'sept-five-math-homework',
  name: 'msExcelOffice2016Practice.shared.files.septFiveMathHomework',
  dateModified: new Date(),
  size: 83,
  type: 'word',
  selected: false
};

export const windowsDocumentTextbookList: IFileExplorerDocument = {
  icon: 'pdf.png',
  id: 'textbook-list',
  name: 'msExcelOffice2016Practice.shared.files.textbookList',
  dateModified: new Date(),
  size: 224,
  type: 'pdf',
  selected: false
};

export const windowsDocumentScienceHomework: IFileExplorerDocument = {
  icon: 'excel.png',
  id: 'science-homework',
  name: 'msExcelOffice2016Practice.shared.files.scienceHomework',
  dateModified: new Date(),
  size: 50,
  type: 'excel',
  selected: false
};
