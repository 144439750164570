import {IWord2016ApplicationConfig} from "../../../../../shared-interface-ms-word-office2016/layout/word-2016-application-config.interface";
import {IHotspot} from "../../../../../shared/interfaces/hotspot.interface";

export const interfacePartsWord2016ComponentConfig: IWord2016ApplicationConfig = {
  common: {
    title: 'sharedInterfaceMicrosoftWord.document1',
    activeInterface: 'document'
  },
  documentInterface: {
    hasRulers: true,
    homeTab: {
      font: {
        family: 'fonts.calibri',
        size: 11
      }
    },
    layoutTab: {
      pageSetup: {
        orientation: 'landscape'
      }
    }
  }
};

export const interfacePartsHotspots: IHotspot[] = [
  {
    id: 'quick-access',
    label: 'sharedInterfaceMicrosoftWord.interfaceParts.quickAccessToolbar'
  },
  {
    id: 'window-controls',
    label: 'sharedInterfaceMicrosoftWord.interfaceParts.windowControls'
  },
  {
    id: 'ribbon',
    label: 'sharedInterfaceMicrosoftWord.interfaceParts.ribbonTabs'
  },
  {
    id: 'ruler',
    label: 'sharedInterfaceMicrosoftWord.interfaceParts.ruler'
  },
  {
    id: 'document-content',
    label: 'sharedInterfaceMicrosoftWord.interfaceParts.documentContent'
  },
  {
    id: 'status-bar',
    label: 'sharedInterfaceMicrosoftWord.interfaceParts.statusBar'
  }
];
