export function gmailUsernameIsValid(submitted: string): boolean {
  const parts = submitted.split('@');

  if (parts.length < 1 || parts.length > 2) {
    return false;
  }

  if (submitted.indexOf(' ') > -1) {
    return false;
  }

  if (parts.length === 2 && parts[1] !== 'gmail.com') {
    return false;
  }

  return true;
}

/**
 * Given an expected Gmail username, see whether what was submitted is valid.
 *
 * @param submitted string Submitted username, e.g. janloggins or j.an.loggins@gmail.com
 * @param expected  string The expected username. Gets normalized by removing @gmail.com or periods. e.g. jan.loggins
 */
export function gmailUsernameMatches(submitted: string, expected: string) {
  if (!gmailUsernameIsValid(submitted)) {
    return false;
  }

  const usernameSubmitted: string = submitted.split('@')[0];
  const usernameExpected: string = expected.split('@')[0];
  const regExPeriods = /\./g;

  return usernameSubmitted.toLowerCase().replace(regExPeriods, '') === usernameExpected.toLowerCase().replace(regExPeriods, '');
}
